// src/About.js
import React from 'react';
import './About.css';

const About = () => {
  return (
    <div className="about">
      <div className="about-content">
        <h1>About Fleeting Thought Software</h1>
        <p>Welcome to Fleeting Thought Software, your trusted partner in innovative software solutions. Our mission is to consult, design, and build custom software for small and mid-sized businesses, helping them to realize their full potential in the digital age.</p>

        <h2>Who We Are</h2>
        <p>At Fleeting Thought Software, we are a team of passionate software developers, designers, and consultants dedicated to delivering high-quality software solutions tailored to meet your business needs. Our expertise spans a wide range of technologies and industries, allowing us to provide versatile and effective solutions.</p>

        <h2>Our Services</h2>
        <ul>
          <li><strong>Consulting:</strong> We provide expert consulting services to help you identify and implement the best technology solutions for your business.</li>
          <li><strong>Custom Software Development:</strong> Our team designs and builds custom software tailored to your specific requirements, ensuring scalability and efficiency.</li>
          <li><strong>Mobile Applications:</strong> We develop engaging and intuitive mobile applications for iOS, Android, and cross-platform Flutter solutions.</li>
          <li><strong>Game Development:</strong> Our game development team brings your creative ideas to life with stunning graphics and immersive gameplay.</li>
          <li><strong>Prototyping:</strong> We create prototypes to help you visualize and refine your software concepts before full-scale development.</li>
          <li><strong>Business Automation:</strong> We streamline your business processes by developing automation solutions that save time and reduce costs.</li>
        </ul>

        <h2>Why Choose Us?</h2>
        <p>Choosing Fleeting Thought Software means partnering with a team that is committed to your success. We believe in building long-term relationships with our clients by delivering exceptional results and providing continuous support. Our approach is client-centric, and we work closely with you to understand your unique challenges and goals.</p>

        <h2>Get In Touch</h2>
        <p>Ready to take your business to the next level with cutting-edge software solutions? Contact us today to learn more about how we can help you achieve your goals.</p>
      </div>
    </div>
  );
};

export default About;
