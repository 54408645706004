// src/Header.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header = () => {
  return (
    <header className="header">
      <div className="logo-container">
        <Link to="/">
          <div className="portfolio-logo"></div>
        </Link>
      </div>
      <div className="title-container">
        <div className="title">Fleeting Thought Software</div>
        <div className="subtitle">Consult. Design. Build.</div>
      </div>
      <nav className="navbar">
        <Link to="/">Home</Link>
        <Link to="/privacy">Privacy</Link>
        <Link to="/about">About</Link>
        <Link to="/contact">Contact</Link>
      </nav>
    </header>
  );
};

export default Header;
