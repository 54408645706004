// src/Privacy.js
import React from 'react';

const Privacy = () => {
  return (
    <main className="content">
      <section className="privacy-policy">
        <h1>Privacy Policy</h1>
        <p>
          At Fleeting Thought Software, we value your privacy and are committed to protecting your personal data. We do not use cookies or collect any personal information without your explicit consent.
        </p>
        <p>
          For our mobile applications, we adhere to strict privacy guidelines to ensure that your data is handled with the utmost care and security.
        </p>
        <h2>Data Collection</h2>
        <p>
          We collect only the data necessary to provide and improve our services. This includes usage data, device information, and any information you provide directly to us.
        </p>
        <h2>Data Use</h2>
        <p>
          The data we collect is used solely for the purpose of enhancing our services and providing you with the best possible experience.
        </p>
        <h2>Data Sharing</h2>
        <p>
          We do not share your personal data with third parties without your consent, except as required by law or to protect our rights.
        </p>
      </section>
    </main>
  );
};

export default Privacy;
