// src/Home.js
import React from 'react';

const Home = () => {
  return (
    <main className="content">
      <section className="intro">
        <h1>Welcome to Fleeting Thought Software</h1>
        <p>
          At Fleeting Thought Software, we consult, design, and build custom software for small and mid-sized businesses. Contact us today to see how we can help launch your idea.
        </p>
      </section>
      <section className="services">
        <div className="service-card">
          <h3>What We Do</h3>
          <p>
            We consult, design, and build custom software for small and mid-sized businesses. Contact us today to see how we can help launch your idea.
          </p>
        </div>
        <div className="service-card">
          <h3>Mobile Applications</h3>
          <p>
            We love making applications for mobile devices. Need a mobile application for your business? We can develop iOS, Android, or cross-platform Flutter mobile applications.
          </p>
        </div>
        <div className="service-card">
          <h3>Game Development</h3>
          <p>
            Whether you develop games independently or are part of a large game development organization, we can help. If you need temporary help to meet a rollout deadline, contact us.
          </p>
        </div>
        <div className="service-card">
          <h3>Prototyping Business Applications</h3>
          <p>
            Communicating with software developers can present a challenge. There is no better way to communicate with your team than to show a working prototype of your concept. We can help.
          </p>
        </div>
        <div className="service-card">
          <h3>Consulting</h3>
          <p>
            Are you looking to expand your IT infrastructure? Getting outside insight during the planning stages can save significant time and money. Let us help you plan your expansion.
          </p>
        </div>
        <div className="service-card">
          <h3>Business Automation</h3>
          <p>
            Let us help reduce costs by automating core functions of your business that require too many man-hours. Some repetitive tasks take a large amount of your staff's time without yielding a return on investment. Let us see if we can help.
          </p>
        </div>
      </section>
    </main>
  );
};

export default Home;
